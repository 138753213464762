import _objectSpread from "/Users/jason/dev/bidatc/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/Users/jason/dev/bidatc/dashboard/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _Dialog2 from "element-ui/lib/theme-chalk/dialog.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dialog from "element-ui/lib/dialog";
import _TimeSelect2 from "element-ui/lib/theme-chalk/time-select.css";
import "element-ui/lib/theme-chalk/base.css";
import _TimeSelect from "element-ui/lib/time-select";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import moment from 'moment';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { Switch } from '@/components/stateless';
import Multiselect from 'vue-multiselect';
import ManageBidYear from '@/components/ManageBidYear';
var FacilityRepository = RepositoryFactory.get('facilities');
export default {
  name: 'facility-admin',
  components: (_components = {
    ManageBidYear: ManageBidYear
  }, _defineProperty(_components, Switch.name, Switch), _defineProperty(_components, _TimeSelect.name, _TimeSelect), _defineProperty(_components, _Dialog.name, _Dialog), _defineProperty(_components, "Multiselect", Multiselect), _components),
  data: function data() {
    return {
      disabled: false,
      facility: {},
      bidHours: [],
      days: [{
        id: 0,
        name: 'Sunday'
      }, {
        id: 1,
        name: 'Monday'
      }, {
        id: 2,
        name: 'Tuesday'
      }, {
        id: 3,
        name: 'Wednesday'
      }, {
        id: 4,
        name: 'Thursday'
      }, {
        id: 5,
        name: 'Friday'
      }, {
        id: 6,
        name: 'Saturday'
      }]
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters({
    is: 'auth/is',
    allowText: 'facility/allowText'
  })), mapState('facility', ['content'])), {}, {
    filteredDays: function filteredDays() {
      return _.difference(this.days, this.selectedDays);
    },

    /** Find days that already have times */
    selectedDays: function selectedDays() {
      var _this = this;

      return _.flatMap(this.bidHours, function (d) {
        return _.map(d.days, function (day) {
          return _this.days[day];
        });
      });
    },
    // canChangeBidYear() {
    //       return this.facility.bid_year === parseInt(moment().format('Y'));
    // },
    timezones: function timezones() {
      return moment.tz.names();
    },
    facilityTimezone: function facilityTimezone() {
      return moment.tz(this.facility.timezone).zoneAbbr();
    } // timezone() {
    //     return window.moment.tz.zone(window.moment.tz.guess()).abbr(window.moment());
    // },

  }),
  methods: _objectSpread(_objectSpread({}, mapMutations(['request'])), {}, {
    /** Save changes to facility */
    saveFacility: function saveFacility() {
      var _this2 = this;

      var payload = JSON.parse(JSON.stringify(this.facility)); // Ensure facility is lowercase

      payload.id = payload.id.toLowerCase(); // Convert times to UTC

      payload.bid_days = this.utcTimes(this.bidHours, this.facility.timezone, ['bid_start', 'bid_end']); // Save the facility

      FacilityRepository.update(this.facility.id, payload).then(function (r) {
        // Update teh facility state
        _this2.$store.commit('facility/setFacility', r.data);

        _this2.setFacility(r.data);
      });
    },
    setFacility: function setFacility(facility) {
      // Clone the facility from vuex
      this.facility = JSON.parse(JSON.stringify(facility)); // Guess the facility timezone if it's not already set

      this.facility.timezone = this.facility.timezone || moment.tz.guess(); // Convert bid hours from JSON

      this.bidHours = JSON.parse(JSON.stringify(this.utcTimes(this.facility.bid_days, this.facility.timezone, ['bid_start', 'bid_end'], true)));
    }
  }),
  created: function created() {},
  mounted: function mounted() {
    // Clone the facility to prevent live edits
    this.setFacility(this.$store.state.facility.facility); // Seed bid days if not set

    if (!this.facility.bid_days) {
      this.facility.bid_days = [{
        days: [],
        bid_start: '',
        bid_end: ''
      }];
    }
  }
};